.create {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.create > h1 {
    display: flex;
    flex-direction: column;
    width: 500px;
    text-align: center;
}

form {
    display: flex;
    flex-direction: column;
    width: 500px;
    gap: 10px;
}

form > div, .input-wrapper > label, .react-datepicker__input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

p {
    margin: 0;
}

.address {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-select {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.address > div > label {
    display: flex;
    flex-direction: column;
}

.save-button {
    align-self: center;
    width: 100px;
}